<template>
<div class="select" @click="showCity">
  <div class="select-text">
    <span v-show="! val">请选择</span>
    <span v-show="val">{{val}}</span>
  </div>
  <div class="select-icon">
    <img src="@/assets/img/right.png" alt="">
  </div>
</div>
</template>

<script>
export default {
  props: ['province', 'city', 'county'],
  name: 'city',
  data () {
    return {
      addressPicker: {},
      asyncData: [],
      asyncSelectedIndex: [0, 0, 0],
    }
  },
  mounted () {
    this.getCity();
  },
  watch: {
    
  },
  computed: {
    val () {
      return this.province + this.city + this.county
    }
  },
  methods: {
    showCity () {
      this.addressPicker.show()
    },
    initCity () {
      this.addressPicker = this.$createCascadePicker({
        title: '省市区',
        data: this.asyncData,
        onSelect: this.selectHandle,
        onCancel: this.cancelHandle
      })
    },
    selectHandle(selectedVal, selectedIndex, selectedText) {
      this.$emit('change', {
        province: selectedText[0],
        city: selectedText[1],
        county: selectedText[2],
      });
    },
    cancelHandle() {
      // 取消
    },
    /**
     * 查询城市
     */
    getCity () {
      const vm = this;
      let postData = {}
      this.$http
        .post("/apply/region", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            if(response.data.body.list.length > 0){
              vm.asyncData = response.data.body.list.map(e => {
                return {
                  value: e.id,
                  text: e.name,
                  children: e.list.map(p => {
                    return {
                      value: p.id,
                      text: p.name,
                      children: p.list.map(d => {
                        return {
                          value: d.id,
                          text: d.name,
                        }
                      })
                    }
                  })
                }
              })
              vm.initCity()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    
  },
}
</script>

<style lang="scss" scoped>
.select{
  display: flex;
  text-align: right;
  padding: 30px 0;
  .select-text{
    flex: 10;
  }
  .select-icon{
    padding-left: 18px;
    img{
      display: block;
      height: 30px;
      width: 18px;
    }
  }
}
</style>
<template>
<div class="out-cont">
  <cube-tab-bar v-model="selectedLabel" show-slider class="taps" style="margin-bottom: 20px;">
    <cube-tab v-for="(item) in tabs" :label="item.label" :key="item.label">
    </cube-tab>
  </cube-tab-bar>
  <cube-tab-panels v-model="selectedLabel">
    <cube-tab-panel  label="寄快递">
      <div class="box shadow">
        <div class="form-group line">
          <div class="label">工单号：</div>
          <div class="cont">
            <input type="text" v-model="form.expressNum" placeholder="工单号">
          </div>
        </div>
      </div>
      <div class="group-name">
        <div class="group-line">
          <div class="group-line-in"></div>
        </div>
        <div class="group-title">寄件地址</div>
      </div>
      <div class="box shadow">
        <div class="form-group line">
          <div class="label">姓名：</div>
          <div class="cont">
            <input type="text" v-model="form.sendManName" placeholder="请填写您的名字">
          </div>
        </div>
        <div class="form-group line">
          <div class="label mini">电话：</div>
          <div class="cont">
            <input type="text" v-model="form.sendManMobile" placeholder="请填写可以联系到您的有效电话号码">
          </div>
        </div>
        <div class="form-group line">
          <div class="label">省市区：</div>
          <div class="cont">
            <city ref="city" 
              :province="form.sendAddress.province"
              :city="form.sendAddress.city"
              :county="form.sendAddress.county"
              @change="upCity($event, 1)"></city>
          </div>
        </div>
        <div class="form-group line">
          <div class="label">地址：</div>
          <div class="cont">
            <input type="text" v-model="form.sendAddress.address" placeholder="请填写您的详细地址">
          </div>
        </div>
      </div>
      <div class="group-name">
        <div class="group-line">
          <div class="group-line-in"></div>
        </div>
        <div class="group-title">收件地址</div>
      </div>
      <div class="box shadow">
        <div class="form-group line">
          <div class="label">姓名：</div>
          <div class="cont">
            <input type="text" v-model="form.recManName" placeholder="请填写您的名字">
          </div>
        </div>
        <div class="form-group line">
          <div class="label mini">电话：</div>
          <div class="cont">
            <input type="text" v-model="form.recManMobile" placeholder="请填写可以联系到您的有效电话号码">
          </div>
        </div>
        <div class="form-group line">
          <div class="label">省市区：</div>
          <div class="cont">
            <city ref="city" 
              :province="form.recManPrintAddr.province"
              :city="form.recManPrintAddr.city"
              :county="form.recManPrintAddr.county"
              @change="upCity($event, 2)"></city>
          </div>
        </div>
        <div class="form-group line">
          <div class="label">地址：</div>
          <div class="cont">
            <input type="text" v-model="form.recManPrintAddr.address" placeholder="请填写您的详细地址">
          </div>
        </div>
      </div>
      <div class="btns">
        <cube-button class="btn" @click="createExpress">寄快递</cube-button>
      </div>
    </cube-tab-panel>
    <cube-tab-panel  label="取消">
      <div class="box shadow">
        <div class="form-group line">
          <div class="label">工单号：</div>
          <div class="cont">
            <input type="text" v-model="form.expressNum" placeholder="工单号">
          </div>
        </div>
        <div class="form-group line">
          <div class="label">取消原因：</div>
          <div class="cont">
            <input type="text" v-model="form.cancelCause" placeholder="取消原因">
          </div>
        </div>
      </div>
      <div class="btns">
        <cube-button class="btn" @click="cancelExpress">取消快递</cube-button>
      </div>
    </cube-tab-panel>
    <cube-tab-panel  label="查询">
      <div class="box shadow">
        <div class="form-group line">
          <div class="label">工单号：</div>
          <div class="cont">
            <input type="text" v-model="form.expressNum" placeholder="工单号">
          </div>
        </div>
      </div>
      <div class="btns">
        <cube-button class="btn" @click="queryExpress">查快递</cube-button>
      </div>
      <div class="res-str">
        <ul class="list">
          <li v-for="(item, index) in resStr" :key="index">
            <div class="time">{{item.time}}</div>
            <div class="text">{{item.context}}</div>
          </li>
        </ul>
      </div>
    </cube-tab-panel>
  </cube-tab-panels>

</div>
</template>

<script>
import city from '@/components/city.vue'
export default {
  components: {
    city
  },
  data () {
    return {
      selectedLabel: '寄快递',
      tabs: [{
        label: '寄快递',
      }, {
        label: '取消',
      }, {
        label: '查询',
      }],
      form: {
        expressNum: '', // 工单号
        sendManName: '', // 寄件人姓名
        sendManMobile: '', // 寄件人手机号
        sendAddress: {
          "province": "", // 省份
          "city": "", // 市
          "county": "", // 区域
          "address": "", // 详细地址
        },
        recManName: '', // 收件人姓名
        recManMobile: '', // 收件人手机号
        recManPrintAddr: {
          "province": "", // 省份
          "city": "", // 市
          "county": "", // 区域
          "address": "", // 详细地址
        },
        cancelCause: '', // 取消原因
      },
      resStr: [], // 查询返回数据
    }
  },
  methods: {
    /**
     * 查快递
     */
    queryExpress () {
      const vm = this;
      let postData = {
        "expressNum": this.form.expressNum,
      }
      vm.$store.commit('updateLoader', true);
      vm.resStr = []
      this.$http
        .post("//182.92.172.233:20740/logistics/express/queryExpress", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000"){
            vm.$createToast({
              txt: '查询成功',
              type: 'success'
            }).show()
            vm.resStr = response.data.body.data
          }else{
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 取消快递
     */
    cancelExpress () {
      const vm = this;
      let postData = {
        "expressNum": this.form.expressNum,
        "cancelCause": this.form.cancelCause,
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("//182.92.172.233:20740/logistics/express/cancelExpress", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000"){
            vm.$createToast({
              txt: '取消成功',
              type: 'success'
            }).show()
          }else{
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 寄快递
     */
    createExpress () {
      const vm = this;
      let postData = {
        "orderId": this.form.expressNum,
        "recManMobile": this.form.recManMobile,
        "recManName": this.form.recManName,
        "recManPrintAddr":  this.form.recManPrintAddr.province + 
                            this.form.recManPrintAddr.city + 
                            this.form.recManPrintAddr.county + 
                            this.form.recManPrintAddr.address,
        "sendAddress":  this.form.sendAddress.province + 
                        this.form.sendAddress.city + 
                        this.form.sendAddress.county + 
                        this.form.sendAddress.address,
        "sendManMobile": this.form.sendManMobile,
        "sendManName": this.form.sendManName,
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("//182.92.172.233:20740/logistics/express/createExpress", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000"){
            vm.$createToast({
              txt: '下单成功',
              type: 'success'
            }).show()
          }else{
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 省市区选择
     */
    showCity() {
      this.$refs.city.$refs.drawer.show()
    },
    upCity (obj, type) {
      if(type == 1){
        for(let key in obj){
          this.form.sendAddress[key] = obj[key];
        }
      }else{
        for(let key in obj){
          this.form.recManPrintAddr[key] = obj[key];
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.val-cont{
  padding: 30px;
  text-align: center;
}


.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 160px;
      &.mini{
        width: 100px;
      }
    }
    .cont{
      flex: 10;
      text-align: right;
      input{
        display: block;
        width: 100%;
        text-align: right;
        padding: 30px 0;
        height: 30px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
    .cont-text{
      padding: 30px 0;
    }
  }
}

.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}
.res-str{
  word-wrap: break-word;
  word-break: break-all;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.list{
  li{
    font-size: 24px;
    margin-bottom: 30px;
    line-height: 1.7;
    .time{
      margin-bottom: 10px;
      color: #208170;
      font-weight: 700;
    }
  }
}
</style>